<template>
  <div class="footer-container">
    <span class="attrix-text">
      <img :src="this.$t('footer.powered_by')=='fr'?iconFr:icon" alt="" class="icon">
    </span>
  </div>
</template>

<script>
import AttrixLogo from '../assets/AttriX_poweredby_whtbg.svg';
import AttrixLogoFr from '../assets/AttriX_propulsepar_whtbg.svg'
export default {
  name: 'FooterComponent',
  data(){
    return {
      icon:AttrixLogo,
      iconFr: AttrixLogoFr
    }
  }
};
</script>

<style scoped>
.icon {
  width: 150px;
}

.footer-container{
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid rgb(200, 200, 200);
}
.attrix-text {
  font-size: 14px;
  float: left;
  padding-left: 15px;
}
</style>
