<template>
  <div class="main-container">
    <div class="header-component-container">
      <Header
        icon="Icon"
        @toggleOptionWindow="toggleOptionWindow = !toggleOptionWindow"
        @toReport="toReport"
        @loading="loading = true"
      />
    </div>
    <router-view/>
    <div class="footer-component-container">
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      devices: [],
      loading: false,
      api: {},
      notification: {},
    };
  },
  created() {
    document.title = 'Niu Toilet Web Portal';
    this.isLoggedIn()
    this.$store.commit('setCredentials');
    this.getUserLanguage;
  },
  computed: {
    getUserLanguage() {
      if (this.isLoggedIn()) {
        let api = this.$store.getters.getApi;
        api.call("Get", {
          "typeName": "User",
          "search": {
              "name": JSON.parse(localStorage.getItem('geotabAPI_credentials')).userName
          }
        }, (user) => {
          this.$i18n.locale = user[0].language;
        }, (err) => {})
      }
    }
  },
  methods: {
    isLoggedIn() {
      this.$store.commit('verifyCredentials');
      return this.$store.getters.getLogin;
    },
    updateNotification: function (val) {
      this.notification = val;
    },
    toReport(val) {
      this.$refs.DeviceList.getReport(val);
    },
    getDate(from, to) {
      return {
        fromDate: new Date(from).toISOString(),
        toDate: new Date(to).toISOString(),
      };
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.main-container {
  display: grid;
  grid-template-areas:
    'header-component-container'
    'body-component-container'
    'footer-component-container';

  grid-template-rows: 66px auto 50px;
  height: 100vh;
}
.main-container > .header-component-container {
  grid-area: header-component-container;
  border-bottom: 1px solid rgb(200, 200, 200);
}

.main-container .body-component-container {
  grid-area: body-component-container;
  height: calc(100vh - 122px);
  overflow: hidden;
}

.main-container > .footer-component-container {
  grid-area: footer-component-container;
}
</style>
