import { createStore } from 'vuex'
import GeotabApi from 'mg-api-js';

export default createStore({
    state () {
      return {
        loggedIn: false,
        api: {},
        exceptionEventsReport: {},
        filteredRules: {},
        devices: {},
        rulesState: {},
        rulesPerDevices: {},
        appliedRules: [
          'aejAYlirbw0acHN2c4v2_8A',
          'a5nx7CQcGU0ezgFEOs3bNBQ',
          'aRgudpF_-k0eUFRNDzXb0GQ',
          'ao8mutfEZj0Wr6xEasqq89g',
          'aV7ZLBTb0HkWT_MXhgH5UGQ',
          'aGXuhU4QXCky9K4m0CF_yAA',
          'asgC5XSp7s0eWcASGRMhQaQ',
          'axpMxILLZdkmUs1xESfknAw',
          'a5mC13jeCoUu1X4w5mUyepg',
          'akOouhvX1hUyFD5kpyl3JFw',
          'ap5-VEXFqQ0KmCfwgu9h1XQ',
          'aGVhP_AxkGUWeOyeeSyDegA',
          'aK2MQCp7D-EKZCa-9j7-5dQ',
          'aEzJaGS0-VEymUOxHvul-rQ',
          'aSwofxCptc0eVoJhNTN2V6A',
          'aX4dF8yp9YkiXGbZ7GQdMuQ',
        ]
      }
    },
    getters: {
      getLogin(state) {
        return state.loggedIn;
      },
      getDevices(state) {
        return state.devices;
      },
      getApi(state) {
        return state.api;
      },
      getExceptionEventsReport(state) {
        return state.exceptionEventsReport;
      },
      getFilteredRules(state) {
        return state.filteredRules;
      },
      getDevices(state) {
        return state.devices;
      },
      getRulesState(state) {
        return state.rulesState;
      },
      getAppliedRules(state) {
        return state.appliedRules;
      },
      getRulesPerDevices(state) {
        return state.rulesPerDevices;
      }
    },
    mutations: {
      login(state) {
        state.loggedIn = true;
      },
      logout(state) {
        if (localStorage.getItem('geotabAPI_credentials')) {
          localStorage.removeItem("geotabAPI_credentials");
        }
        state.loggedIn = false;
      },
      verifyCredentials(state) {
        if(localStorage.getItem('geotabAPI_credentials')) {
          state.loggedIn = true;
        }
      },
      setDevices(state, data) {
        state.devices = cloneDeep(data);
        console.log('mutated')
      },
      setCredentials(state) {
        let storage = JSON.parse(localStorage.getItem('geotabAPI_credentials'));

        let credentials = {};

        if(state.loggedIn && localStorage.getItem('geotabAPI_credentials')) {
          credentials = {
            credentials: {
              database: storage.database,
              sessionId: storage.sessionId,
              userName: storage.userName,
            },
            path: 'my.geotab.com',
          };
          
          state.api = new GeotabApi(credentials);
        }
      },
      setExceptionEventsReport(state, data) {
        state.exceptionEventsReport = data;
      },
      setFilteredRules(state, data) {
        state.filteredRules = data;
      },
      setDevices(state, data) {
        state.devices = data;
      },
      setRulesState(state, data) {
        state.rulesState = data;
      },
      setRulesPerDevices(state, data) {
        state.rulesPerDevices = data;
      }
    }
  })