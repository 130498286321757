<template>
  <div class="header-container">
    <nav class="navbar navbar-expand-sm nav-wrapper">
      <div>
        <button type="button" class="icon-btn" @click="redirectToIndex()">
          <img :src="icon" class="icon"/>
        </button>

      </div>
      <div v-if="isLoggedIn()" class="hamburger-bars-icon-container">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon">
            <font-awesome-icon icon="fa-solid fa-bars" />
          </span>
        </button>
      </div>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto nav-list">
          <li v-if="isLoggedIn()" class="nav-item header-page-container" data-toggle="collapse" data-target=".navbar-collapse.show">
            <div class="page-link-container">
              <router-link :class="isSelectedPage('/') ? 'link-btn-selected' : 'link-btn'" to="/">{{ $t('header.devices') }}</router-link>
            </div>
          </li>
          <li v-if="isLoggedIn()" class="nav-item header-page-container" data-toggle="collapse" data-target=".navbar-collapse.show">
            <div class="page-link-container">
              <router-link :class="isSelectedPage('/reports') ? 'link-btn-selected' : 'link-btn'" to="/reports">{{ $t('header.reports') }}</router-link>
            </div> 
          </li>
          <li v-if="isLoggedIn() && isAuthorizedSettings()" class="nav-item header-page-container" data-toggle="collapse" data-target=".navbar-collapse.show">
            <div class="page-link-container">
              <router-link :class="isSelectedPage('/settings') ? 'link-btn-selected' : 'link-btn'" to="/settings">{{ $t('header.settings') }}</router-link>
            </div> 
          </li>
        </ul>
        <ul class="navbar-nav ml-auto">
          <div class="logged-in-username-logout-wrapper">
            <li v-if="isLoggedIn()" id="username-content-id">
              <div class="dropdown">
                <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ getUsername() }}
                </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <LocalSwitcher class="dropdown-item"></LocalSwitcher>
                  <LogoutButton class="dropdown-item" @logoutEvent="logout()"></LogoutButton>
                </div>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import LogoutButton from '../components/LogoutButton.vue';
import LocalSwitcher from '../components/LocaleSwitcher.vue';
import NiuLogo from '../assets/LOGO-NIU-smart-toilet-Black-300x300.png';
export default {
  name: 'HeaderComponent',
  components: {
    LogoutButton,
    LocalSwitcher,
  },
  data() {
    return {
      icon: NiuLogo,
    };
  },
  computed() {

  },
  methods: {
    getUsername() {
      return JSON.parse(localStorage.getItem('geotabAPI_credentials'))
        ? JSON.parse(localStorage.getItem('geotabAPI_credentials')).userName
        : '';
    },
    updateNotification(val){
      this.$emit('updateNotification',val)
    },
    emitToReport(val) {
      this.$emit('toReport', val);
    },
    logout() {
      this.$emit('logoutUser');
    },
    isLoggedIn() {
      return this.$store.getters.getLogin;
    },
    redirectToIndex() {
      if (!this.isLoggedIn()) {
        this.$router.push({ name: 'login' });
      }
      this.$router.push({ name: 'index' });
    },
    isSelectedPage(page) {
      return this.$route.path === page;
    },
    isAuthorizedSettings() {
      // todo: to complete
      return true;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.icon-btn {
  border: none;
  background: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
}

.icon {
  width: 50px;
  height: 50px;
}

.login-button {
  float: right;
}

.logged-in-username-logout-wrapper {
  display: inline-flex;
}

#username-content-id {
  /* margin-top: 11px; */
  padding-right: 10px;

}

.nav-wrapper {
  z-index: 10;
  min-width: 300px;
  padding: 8px 10px;
}

.local-switcher {
  margin-top: 3px;
  padding-right: 14px;
}

.header-container .navbar-nav{
  flex-direction: row;
}

.navbar {
  flex-wrap: wrap;
  align-items: unset;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute !important; 
  padding: 0;
  box-shadow: -1px 1px 0px 0px #ccc;
}

.dropdown-item {
  border-bottom: 1px solid rgb(220, 220, 220);
  padding-left: 10px;
}

.header-page-container {
  font-size: 15px;
  /* margin-top: 16px; */
}

.link-btn {
  color: #333;
}

.link-btn:hover {
  color: rgb(0, 179, 0);
  font-weight: 600;
  text-decoration: none;
}

.link-btn-selected {
  color: rgb(0, 179, 0);
  font-weight: 600;
  text-decoration: none;
}

.page-link-container {
  margin-left: 16px;
  font-weight: 500;
}

.hamburger-bars-icon-container {
  margin-top: 9px;
}

@media only screen and (max-width: 575px) {
  .navbar {
    background-color: #fff;
    border-bottom: 1px solid #ccc
  }

  .nav-list {
    flex-direction: column !important;
  }

  .page-link-container {
    margin-left: 4px;
    font-weight: 500;
    padding: 4px 0 4px;
  }

  .dropdown-menu {
    position: unset !important;
    padding: 0;
    box-shadow: -1px 1px 0px 0px #ccc;
  }
}
</style>
