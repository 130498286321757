<template>
  <div class="ge-container">
    <div class="loading-animation-container" v-if="!loaded">
      <div class="loading-animation">
        <Loading/>
      </div>
    </div>
    <div v-else class="body-container">
      <Modal :title="$t('notification.alert')"
             :message=ioxRelayModalMessage
             :closeButton="$t('settings.mailing_list.close_modal')"
             @closeModal="dataModal = false" :showModal="dataModal"/>
      <div class="list-group" id="list-tab" role="tablist">
        <div v-for="(device, deviceId) in rulesPerDevices" class="device-box" :key="deviceId">
          <div class="device-name-container">
            <div class="device-name">
              <span>{{ device.name }}</span>
            </div>
            <div class="from-now">
              <span>{{ moment(device.dateTime).locale($i18n.locale).fromNow() }}</span>
            </div>
            <span class="right-container">
              <span class="chargeIcon chargeBatteryIcon" style="width: 15px; margin-right: 3px">
                <img v-if="devicesList[deviceId].battery >= 12" :src="batteryFull"/>
                <img v-else-if="devicesList[deviceId].battery >= 10.5" :src="batteryHalf"/>
                <img v-else :src="batteryEmpty" />
              </span>
              <span>
                <img
                  :src="devicesList[deviceId].signal <= 85 ? signals.strong : devicesList[deviceId].signal <= 100 ? signals.ok : signals.low"
                  style="width: 20px"
                />
              </span>
            </span>
          </div>
          <div class="device-info">
            <div v-for="(rule, ruleId) in device.rules" :key="ruleId">
              <div class="device-info-text" v-if="rulesState[ruleId][rule.isActive ? 1 : 0]">
                <div v-if="rule.isActive" class="rule-color" :style="{ 'background-color': rule.colorFormatted }"></div>
                <div v-else class="rule-color"></div>
                <div class="icon-container">
                  <font-awesome-icon :icon="rulesState[ruleId].icon" class="icon"/>
                </div>
                <div class="rule-name">
                  {{ $t(rulesState[ruleId][rule.isActive ? 1 : 0]) }}
                </div>
              </div>
            </div>
          </div>
          <div style="width: fit-content" @click="sendIoxOutputRelaySignal(deviceId)">
            <div class="lock-container">
              <div class="lock-icon-container">
                <button type="button" class="lock-icon-btn">
                  <font-awesome-icon icon="fa-solid fa-lock" class="lock-icon" />
                </button>
              </div>
              <div class="lock-unlock-label">
                <span>
                  {{ $t('device.lock-unlock') }}
                </span>
              </div>
            </div>
            </div>
        </div>
      </div>
      <Map
        ref="map"
        :api="getApi"
        :exceptions="exceptions"
        :rulesPerDevices="rulesPerDevices"
        :devicesList="devicesList"
      />
    </div>
  </div>
</template>

<script>
import Map from './Map.vue';
import { cloneDeep } from 'lodash';
import Report from './Report.vue';
import moment from 'moment';
import BatteryFull from '../assets/battery-full.svg';
import BatteryHalf from '../assets/battery-half.svg';
import BatteryEmpty from '../assets/battery-empty.svg';
import SignalLow from '../assets/signal-low.png';
import SignalOk from '../assets/signal-ok.png';
import Signal from '../assets/signal.png';
import Loading from '../components/Loading.vue';
import Modal from '../components/Modal.vue'

export default {
  name: 'DeviceListComponent',
  components: { Map, Report, Loading, Modal },
  data() {
    return {
      loaded: true,
      moment: moment,
      dataModal: false,
      ioxRelayModalMessage: '',
      signals: {
        low: SignalLow,
        ok: SignalOk,
        strong: Signal,
      },
      batteryFull: BatteryFull,
      batteryHalf: BatteryHalf,
      batteryEmpty: BatteryEmpty,
      url: 'https://my.geotab.com/apiv1',
      rules: [],
      localStorage: null,
      rulesWithColors: [],
      filteredRules: {},
      rulesPerDevices: {},
      exceptions: [],
      exceptionEventsReport: {},
      devicesList: {},
      rulesState: {
        a5mC13jeCoUu1X4w5mUyepg: {
          0: 'rules.toilet_1_off',
          1: 'rules.toilet_1_on',
          icon: 'fa-solid fa-user',
        },
        akOouhvX1hUyFD5kpyl3JFw: {
          0: 'rules.toilet_2_off',
          1: 'rules.toilet_2_on',
          icon: 'fa-solid fa-user',
        },
        'ap5-VEXFqQ0KmCfwgu9h1XQ': {
          0: 'rules.toilet_3_off',
          1: 'rules.toilet_3_on',
          icon: 'fa-solid fa-user',
        },
        a5nx7CQcGU0ezgFEOs3bNBQ: {
          0: 'rules.toilet_1_panic_off',
          1: 'rules.toilet_1_panic_on',
          icon: 'fa-solid fa-circle-exclamation',
        },
        'aRgudpF_-k0eUFRNDzXb0GQ': {
          0: 'rules.toilet_2_panic_off',
          1: 'rules.toilet_2_panic_on',
          icon: 'fa-solid fa-circle-exclamation',
        },

        ao8mutfEZj0Wr6xEasqq89g: {
          0: 'rules.toilet_1_off',
          1: 'rules.toilet_1_on',
          icon: 'fa-solid fa-user',
        },
        aV7ZLBTb0HkWT_MXhgH5UGQ: {
          0: 'rules.toilet_2_off',
          1: 'rules.toilet_2_on',
          icon: 'fa-solid fa-user',
        },
        // "arW8JCvqVc0yindKE87a-TQ": 'Waste tank ok',
        aGXuhU4QXCky9K4m0CF_yAA: {
          1: 'rules.water_5',
          0: '',
          icon: 'fa-solid fa-water',
        },
        asgC5XSp7s0eWcASGRMhQaQ: {
          1: 'rules.water_25',
          0: '',
          icon: 'fa-solid fa-water',
        },
        aSwofxCptc0eVoJhNTN2V6A: {
          1: 'rules.water_25',
          0: '',
          icon: 'fa-solid fa-water',
        },
        aX4dF8yp9YkiXGbZ7GQdMuQ: {
          1: 'rules.water_5',
          0: '',
          icon: 'fa-solid fa-water',
        },
        asJGHgZeI2kKXvSaZAs3FKA: {
          1: 'rules.water_ok',
          icon: 'fa-solid fa-water',
        },
        axpMxILLZdkmUs1xESfknAw: {
          0: 'rules.waste_ok',
          1: 'rules.waste_full',
          icon: 'fa-solid fa-water',
        },
        'aEzJaGS0-VEymUOxHvul-rQ': {
          0: 'rules.waste_ok',
          1: 'rules.waste_full',
          icon: 'fa-solid fa-water',
        },
        aejAYlirbw0acHN2c4v2_8A: {
          0: 'rules.security_off',
          1: 'rules.security_on',
          icon: 'fa-solid fa-shield-halved',
        },
        aGVhP_AxkGUWeOyeeSyDegA: {
          0: 'rules.panic_off',
          1: 'rules.panic_on',
          icon: 'fa-solid fa-circle-exclamation',
        },
        'aK2MQCp7D-EKZCa-9j7-5dQ': {
          0: 'rules.temperature_off',
          1: 'rules.temperature_on',
          icon: 'fa-solid fa-temperature-half',
        },
      },
      multiCallBattery: [],
      multiCallSignal: [],
    };
  },
  props: ['devices'],
  created() {
    if (!this.isLoggedIn()) {
      this.$router.push({ name: 'login' });
    } else {
      this.$store.commit('setCredentials');
      this.initiateRules();
      this.$store.commit('setRulesState', this.rulesState);
    }
  },
  methods: {
    getApi() {
      return this.$store.getters.getApi;
    },
    isLoggedIn() {
      this.$store.commit('verifyCredentials');
      return this.$store.getters.getLogin;
    },
    startMultiCall(multiCall) {
      return new Promise((resolve, reject) => {
        if (!multiCall.length) {
          resolve([]);
        }
        this.getApi().multiCall(
          multiCall,
          function (logs) {
            resolve(logs);
          },
          reject
        );
      });
    },
    async filterRules() {
      let rules = await this.getApi().call('Get', {
        typeName: 'Rule',
      });

      rules = rules.reduce((acc, rule) => {
        acc[rule.id] = rule;
        return acc;
      }, {});
      this.filteredRules = this.$store.getters.getAppliedRules.reduce((acc, ruleId) => {
        if (rules[ruleId]) {
          acc[ruleId] = rules[ruleId];
        }
        return acc;
      }, {});

      this.$store.commit('setFilteredRules', this.filteredRules);
    },
    sortByName(a, b) {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      return 0;
    },
    async initiateRules() {
      this.exceptions = await this.getDeviceInfo();
      await this.getRules();

      try {
        this.$refs.map.displayMap(this.exceptions);
      } catch {};
    },
    async getRules() {
      this.loaded = false;
      await this.filterRules();
      this.getColors();
      await this.setRulesPerDevices();
      this.mapActiveRules();
      this.loaded = true;
    },
    getColors() {
      for (var ruleId in this.filteredRules) {
        let rule = this.filteredRules[ruleId];
        if (rule) {
          var color = {
            r: rule.color.r,
            g: rule.color.g,
            b: rule.color.b,
            a: rule.color.a,
          };
          rule.colorFormatted = 'rgba(' + color.r + ', ' + color.g + ', ' + color.b + ', ' + color.a + ')';
        }
      }
    },
    getDeviceInfo() {
      return new Promise((resolve, reject) => {
        this.getApi().call(
          'Get',
          {
            typeName: 'DeviceStatusInfo',
          },
          function (result) {
            if (result != null && result.length > 0) {
              resolve(result);
            }
          },
          function (errorString) {
            reject(errorString);
          }
        );
      });
    },
    async setRulesPerDevices() {
      const devices = await this.getTheDevices();

      for (var device of devices) {
        this.multiCallBattery.push([
          'Get',
          {
            typeName: 'StatusData',
            search: {
              fromDate: new Date().toISOString(),
              deviceSearch: { id: device.id },
              diagnosticSearch: { id: 'DiagnosticGoDeviceVoltageId' },
            },
          },
        ]);
        this.multiCallSignal.push([
          'Get',
          {
            typeName: 'StatusData',
            search: {
              fromDate: new Date().toISOString(),
              deviceSearch: { id: device.id },
              diagnosticSearch: { id: 'a4Cq3dWtCV0yoo9j_v__LbQ' },
            },
          },
        ]);

        let groupType;

        for (let i = 0; i < device.groups.length; i++) {
          let group = device.groups[i];
          if (group.id == 'GroupVehicleId') {
            groupType = 'vehicle';
            break;
          }

          if (group.id == 'GroupTrailerId') {
            groupType = 'trailer';
            break;
          }
        }

        this.devicesList[device.id] = {
          id: device.id,
          name: device.name,
          group: groupType,
          groups: device.groups
        };

        this.rulesPerDevices[device.id] = {
          name: device.name,
          rules: cloneDeep(this.getRulesPerDevice(device.id))
        };
      }
      this.$store.commit('setDevices', this.devicesList);
      this.$store.commit('setRulesPerDevices', this.rulesPerDevices);
      await this.batterySignal();
    },
    async batterySignal() {
      (await this.startMultiCall(this.multiCallBattery)).forEach((battery) => {
        if (battery[0]) {
          this.devicesList[battery[0].device.id].battery = battery[0].data;
        }
      });
      (await this.startMultiCall(this.multiCallSignal)).forEach((signal) => {
        if (signal[0]) {
          this.devicesList[signal[0].device.id].signal = signal[0].data;
        }
      });
    },
    mapActiveRules() {
      this.exceptions.forEach((item) => {
        this.rulesPerDevices[item.device.id].dateTime = item.dateTime;

        item.exceptionEvents.forEach((event) => {
            if (this.rulesPerDevices[item.device.id].rules[event.rule.id]) {
                this.rulesPerDevices[item.device.id].rules[event.rule.id].isActive = 1;
            }
        });
      });
      for (const deviceId in this.rulesPerDevices) {
        if (
          (this.rulesPerDevices[deviceId].rules['aGXuhU4QXCky9K4m0CF_yAA'] &&
            !this.rulesPerDevices[deviceId].rules['aGXuhU4QXCky9K4m0CF_yAA']
              .isActive &&
            this.rulesPerDevices[deviceId].rules['asgC5XSp7s0eWcASGRMhQaQ'] &&
            !this.rulesPerDevices[deviceId].rules['asgC5XSp7s0eWcASGRMhQaQ']
              .isActive) ||
          (this.rulesPerDevices[deviceId].rules['aX4dF8yp9YkiXGbZ7GQdMuQ'] &&
            !this.rulesPerDevices[deviceId].rules['aX4dF8yp9YkiXGbZ7GQdMuQ']
              .isActive &&
            this.rulesPerDevices[deviceId].rules['aSwofxCptc0eVoJhNTN2V6A'] &&
            !this.rulesPerDevices[deviceId].rules['aSwofxCptc0eVoJhNTN2V6A']
              .isActive)
        ) {
          this.rulesPerDevices[deviceId].rules['asJGHgZeI2kKXvSaZAs3FKA'] = {
            color: '#fff',
            icon: 'fa-solid fa-check',
            isActive: 1,
          };
        }
      }
    },
    getTheDevices() {
      return new Promise((resolve, reject) => {
        this.getApi().call(
          'Get',
          {
            typeName: 'Device',
          },
          function (devices) {
            resolve(devices);
            this.$store.commit('setDevices', devices);
          },
          reject
        );
      });
    },
    getGroupsPerDevice(deviceId) {
      return this.devicesList[deviceId].groups.map(g => g.id);
    },
    getGroupsPerRule(rule) {
      for (var i = 0; i < Object.keys(this.filteredRules).length; i++) {
        if (Object.keys(this.filteredRules)[i] === rule) {
          return Object.values(this.filteredRules).find(r => r.id === rule).groups;
        }
      }
    },
    getRulesPerDevice(deviceId) {
      let rules = {};

      // Check if there is at least a group that belongs to the device groups and the rule groups
      // if so add the key:value pair to the object <<rules>>
      for (var key in this.filteredRules) {
        if (this.filteredRules.hasOwnProperty(key)
        && JSON.parse(JSON.stringify(this.getGroupsPerRule(key))).some(group => JSON.parse(JSON.stringify(this.getGroupsPerDevice(deviceId))).includes(group.id))) {
          rules[key] = this.filteredRules[key];
        }
      }
      return rules;
    },
    async sendIoxOutputRelaySignal(deviceId) {
      try {
        await this.$store.getters.getApi.call('Add', {
          typeName: 'TextMessage',
          entity: {
              device: {
                  id: deviceId
              },
              messageContent: {
                  isRelayOn: true,
                  contentType: 'IoxOutput'
              },
              isDirectionToVehicle: true
          }
        }).then(() => {
          this.$store.getters.getApi.call('Add', {
            typeName: 'TextMessage',
            entity: {
                device: {
                    id: deviceId
                },
                messageContent: {
                    isRelayOn: false,
                    contentType: 'IoxOutput'
                },
                isDirectionToVehicle: true
            }
          }).then(() => {
            // Pop modal
            this.dataModal = true;
            this.ioxRelayModalMessage = this.$t('notification.iox-output-relay-message-succes');
          });
        });
      } catch {
        // Pop modal
        this.dataModal = true;
        this.ioxRelayModalMessage = this.$t('notification.iox-output-relay-message-error');
      }
    }
  }
};
</script>

<style>
.list-group {
  height: calc(100vh - 116px);
  overflow-y: scroll;
}
.ge-container {
  height: 100%;
}
.map-container {
  position: relative;
  flex: 1;
  height: 100%;
}

.device-info {
  display: block;
  height: auto;
  border-top: 1px solid rgb(220, 220, 220);
  margin-top: 10px;
  clear: both;
}

.device-info-text {
  display: flex;
  padding-left: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.device-box {
  flex: 1;
  background-color: #fff;
  width: 420px;
  margin: 0 0 8px 0;
}

.refresh {
  cursor: pointer;
  margin-top: 0;
  background-color: #fff;
  width: 28px;
  height: 28px;
  margin-top: 5px;
  border: 1px solid rgb(210, 210, 210);
  border-radius: 3px;
}

.refresh:hover {
  background-color: #f7f8f8;
}

.device-name-container {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.device-name {
  font-weight: 600;
  position: relative;
}

.rule-name {
  padding-left: 8px;
  text-align: left;
  flex: 0 0 75%;
}

.rule-color {
  border-radius: 3px;
  width: 14px;
  height: 14px;
  margin-top: 4px;
}

.icon-container {
  padding-left: 15px;
}

.icon {
  width: 15px;
  height: 15px;
}

/* body & map */
.body-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: rgb(220, 220, 220);
}

.device-list-container {
  flex: 0 0 23%;
  height: auto;
}

.batteryState_0 {
  fill: red;
}
.batteryState_50 {
  fill: #f2c101;
}
.chargeIcon {
  display: flex;
  font-size: 0.79em;
  align-items: center;
}
.right-container {
  display: flex;
  padding-right: 12px;
  position: absolute;
  right: 0;
  top: 0;
}

.from-now {
  font-weight: 300;
  position: absolute;
  font-size: 10px;
  bottom: -12px;
  left: 0;
  width: 100%;
  text-align: center;
}

.loading-animation {
  text-align: center;
  margin-top: 40px;
}

.loading-animation-container {
  display: flex;
  justify-content: center;
}

.lock-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 11px;
  margin-top: 16px;
}

.lock-icon-container {
  margin-left: 21px;
  left: 0;
}

.lock-icon {
  color: #555;
}

.lock-unlock-label {
  font-size: 12px;
  margin-left: 7px;
  padding-top: 3px;
}

.lock-icon-btn {
  border: none;
  padding: 0;
  background: none;
}

@media only screen and (max-width: 840px) {
  .body-container{ 
    flex-direction: column;
  }

  .list-group {
    height: calc(100vh - 440px);
    overflow-y: scroll;
  }

  .map-container {
    margin-top: 2px;
    margin-left: 0;
  }
  
  .device-box {
    width: 100%;
    margin: 0 0 5px 0;
  }
}
</style>
