<template>
  <div class="report">
    <div class="report-container">
      <div class="timestamps">
        <div class="period-container">
          <div v-for="periodOption in periodOptions" :key="periodOption.date" class="period-label-container">
            <button
              type="button"
              @click="toApply(periodOption)"
              class="period-label"
              :class="setSelectedPeriodHighlight(periodOption)"
              :id="periodOption.date.replace('-', '')"
              name="period"
            >{{ $t(`timestamp.${periodOption.date}`) }}</button>
          </div>
        </div>
        <div class="custom-date-input-container-wrapper">
          <div class="custom-date-input-container">
            <div class="to-from-date-input-container">
              <input :class="hasInvalidInput ? 'custom-date-input-error' : 'custom-date-input'" 
                    v-model="pickedPeriodCustom.from" :placeholder="$t('timestamp.date_format')" 
                    id="fromDate"
                    type="date"
                    :max="restrictFuturDates()"/>
              <div class="to-label">
                {{ $t('timestamp.to') }}
              </div>
              <input :class="hasInvalidInput ? 'custom-date-input-error' : 'custom-date-input'"
                      v-model="pickedPeriodCustom.to"
                      :placeholder="$t('timestamp.date_format')"
                      id="toDate"
                      type="date"
                      :max="restrictFuturDates()"/>
            </div>
            <div>
              <button type="button" @click="toApply(customPeriodOption)" class="apply-custom-date-button">{{ $t('timestamp.apply_custom_date') }}</button>
            </div>
          </div>
          <div class="invalid-input-message" v-if="customDatesInversed">
            <span>{{ $t("timestamp.custom_from_to_error") }}</span>
          </div>
          <div class="invalid-input-message" v-if="intervalOver90Days">
            <span>{{ $t("timestamp.90days") }}</span>
          </div>
          <div class="invalid-input-message" v-if="customDatesInputEmpty">
            <span>{{ $t("timestamp.date_input_empty") }}</span>
          </div>
        </div>
      </div>
      <div class="report-cards">
        <div class="loading-animation-container" v-if="!loaded">
          <div class="loading-animation">
            <Loading/>
          </div>
        </div>
        <div class="no-exception-to-show" v-else-if="noExceptions()">
          <span>{{ $t('exception.no-exception-found') }}</span>
        </div>
        <div v-else v-for="(deviceContent, deviceId) in getExceptionEventsReport" :key="deviceId" class="device-box-container">
          <div class="device-box">
            <div class="device-name-container-report">
              <span class="device-name">{{ getDevices[deviceId].name }}</span>
            </div>
            <div class="device-info">
              <div v-for="(rule, ruleId) in getFilteredRules" :key="ruleId">
                <div v-if=" deviceContent[ruleId] && deviceContent[ruleId].events.length" class="item">
                  <input type="checkbox" :id="ruleId + deviceId" />
                  <label :for="rule.id + deviceId" class="rule-name-label">
                    <div class="device-info-text">
                      <div class="rule-name">{{ $t(getRulesState[ruleId][1]) }}</div>
                      <div>
                        {{ formatTotalEventsTimestamp(deviceContent, ruleId) }}
                      </div>
                      <div class="rule-chevron-icon-container">
                        <font-awesome-icon icon="fa-solid fa-chevron-down" />
                      </div>
                    </div>
                  </label>
                  <div class="event-containers">
                    <div v-for="(event, index) in deviceContent[ruleId].events" :key="index" class="event-container">
                      <div>
                        {{ moment
                            .utc(event.activeFrom)
                            .local()
                            .format('YYYY-MM-DD HH:mm:ss') }}
                        ～
                        {{ moment
                            .utc(event.activeTo)
                            .local()
                            .format('YYYY-MM-DD HH:mm:ss') }}
                      </div>
                      <div>
                        {{ formatEventTimestamp(event) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Loading from '@/components/Loading.vue';

export default {
  name: 'Report',
  components: {
    Loading
  },
  data() {
    return {
      loaded: true,
      moment: moment,
      pickedPeriodCustom: { from: '', to: '' },
      pickedPeriod: 'Today',
      periodOptions: [
        {
          date: 'Today',
          selected: false
        }, 
        {
          date: 'Yesterday',
          selected: false
        },
        {
          date: 'This-week',
          selected: false
        },
        {
          date: 'Last-week',
          selected: false
        },
      ],
      customPeriodOption: {
        date: "Custom"
      },
      closeReportIcon: "fa-solid fa-xmark",
      customDatesInversed: false,
      intervalOver90Days: false,
      customDatesInputEmpty: false
    };
  },
  created() {
    if (!this.isLoggedIn()) {
      this.$router.push({ name: 'login' });
    }

    if (this.getDevices !== undefined && Object.keys(this.getDevices).length <= 0) {
      this.$router.push({ name: 'index' });
    }

    this.setDefaultPeriod();
  },
  computed: {
    hasInvalidInput() {
      return this.customDatesInversed || this.intervalOver90Days || this.customDatesInputEmpty;
    },
    getExceptionEventsReport() {
      return this.$store.getters.getExceptionEventsReport;
    },
    getFilteredRules() {
      return this.$store.getters.getFilteredRules;
    },
    getDevices() {
      return this.$store.getters.getDevices;
    },
    getRulesState() {
      return this.$store.getters.getRulesState;
    }
  },
  methods: {
    isLoggedIn() {
      this.$store.commit('verifyCredentials');
      return this.$store.getters.getLogin;
    },
    redirectToIndex() {
      this.$router.push({ name: 'index' });
    },
    getPeriodInterval(period) {
      let dates = this.getPeriod(period);
      this.customDatesInversed = false;
      this.intervalOver90Days = false;
      this.customDatesInputEmpty = false;

      if (!this.customInputValid(period, dates)) {
        return false;
      };
      
      return dates;
    },
    async toApply(period) {
      this.loaded = false;
      let interval = this.getPeriodInterval(period.date);

      if (interval === false) {
        this.loaded = true;
        return;
      }

      await this.getReport(interval);
      period.selected = true;
      this.resetLabelHighlight(period);
      this.loaded = true;
    },
    getPeriod(period) {
      let dates = {};

      if (period === 'This-week' || period === 'Last-week') {
        period = period.replace('-', '');
      }

      switch (period) {
        case 'Today':
          dates.from = moment()
            .startOf('day')
            .utc()
            .toISOString();
          dates.to = moment().endOf('day').utc().toISOString();
          break;
        case 'Yesterday':
          dates.from = moment()
            .subtract(1, 'days')
            .startOf('day')
            .utc()
            .toISOString();
          dates.to = moment()
            .subtract(1, 'days')
            .endOf('day')
            .utc()
            .toISOString();
          break;
        case 'Thisweek':
          dates.from = moment()
            .startOf('week')
            .utc()
            .toISOString();
          dates.to = moment().endOf('week').utc().toISOString();
          break;
        case 'Lastweek':
          dates.from = moment()
            .subtract(1, 'week')
            .startOf('week')
            .utc()
            .toISOString();
          dates.to = moment()
            .subtract(1, 'week')
            .endOf('week')
            .utc()
            .toISOString();
          break;
        case 'Custom':
          dates.from = moment(this.pickedPeriodCustom.from)
            .utc()
            .toISOString();
          dates.to = moment(this.pickedPeriodCustom.to)
            .utc()
            .toISOString();
          break;
        default:
          dates.from = moment()
            .startOf('day')
            .utc()
            .toISOString();
          dates.to = moment().endOf('day').utc().toISOString();
          break;
      }
      return dates;
    },
    async getReport(interval) {
      let exceptionEventCall = this.stackEventCalls(interval);
      let result = await this.startMultiCall(exceptionEventCall);
      this.populateReport(result);
    },
    startMultiCall(multiCall) {
    return new Promise((resolve, reject) => {
      if (!multiCall.length) {
        resolve([]);
      }
      this.$store.getters.getApi.multiCall(
        multiCall,
        function (logs) {
          resolve(logs);
        },
        reject
      );
    });
    },
    stackEventCalls(interval) {
      let exceptionEventCall = [];

      this.$store.getters.getAppliedRules.forEach((rule) => {
        exceptionEventCall.push([
          'Get',
          {
            typeName: 'ExceptionEvent',
            search: {
              fromDate: interval.from,
              toDate: interval.to,
              ruleSearch: { id: rule, includeZoneStopRules: false },
              includeDismissedEvents: false,
            },
          },
        ]);
      });

      return exceptionEventCall;
    },
    populateReport(exceptionEventCalls) {
      let result = {};

      exceptionEventCalls.forEach((ruleEvents) => {
        ruleEvents.forEach((event) => {
          if (result[event.device.id]) {
            if (result[event.device.id][event.rule.id]) {
              result[event.device.id][event.rule.id].events.push(event);
              result[event.device.id][event.rule.id].duration.add(
                moment.duration(event.duration)
              );
            } else {
              result[event.device.id][event.rule.id] = {
              events: [event],
              duration: moment.duration(event.duration),
              };
            }
          } else {
            result[event.device.id] = {
              [event.rule.id]: {
                events: [event],
                duration: moment.duration(event.duration),
              },
            };
          }
        });
      });

      this.$store.commit('setExceptionEventsReport', result);
      return result;
    },
    setDefaultPeriod() {
      let index = this.periodOptions.findIndex(p => p.date === 'Today');
      this.toApply(this.periodOptions[index]);
    },
    setSelectedPeriodHighlight(period) {
      return period.selected ? 'period-label-selected' : 'period-label';
    },
    resetLabelHighlight(selectedPeriod) {
      for (let period in this.periodOptions) {
        if (this.periodOptions[period].date !== selectedPeriod.date) {
          this.periodOptions[period].selected = false;
        }
      }
    },
    noExceptions() {
      return Object.keys(this.getExceptionEventsReport).length <= 0;
    },
    formatEventTimestamp(event) {
      if (this.moment.duration(event.duration).minutes() <= 0) {
        return this.moment.duration(event.duration).seconds() + 's'
      }
      if (this.moment.duration(event.duration).hours() <= 0) {
        return this.moment.duration(event.duration).minutes() + 'm' +
        this.moment.duration(event.duration).seconds() + 's'
      }
      return this.moment.duration(event.duration).hours() + 'h' +
      this.moment.duration(event.duration).minutes() + 'm' +
      this.moment.duration(event.duration).seconds() + 's'
    },
    formatTotalEventsTimestamp(deviceContent, ruleId) {
      let totalDuration = this.moment.utc(deviceContent[ruleId].duration.as('milliseconds'));

      if (totalDuration < 60000) {
        return this.$t('timestamp.occurences', deviceContent[ruleId].events.length) + ' ' + totalDuration.format('s[s]')
      }
      if (totalDuration < 3600000) {
        return this.$t('timestamp.occurences', deviceContent[ruleId].events.length) + ' ' + totalDuration.format('m[m]s[s]')
      }
      return this.$t('timestamp.occurences', deviceContent[ruleId].events.length) + ' ' + totalDuration.format('H[h]m[m]s[s]')
    },
    customDatesAreInversed(from, to) {
      return from > to;
    },
    customDateIntervalOver90Days(from, to) {
      let timeDelta = new Date(from).getTime() - new Date(to).getTime();
      let daysDelta = Math.abs(timeDelta / (1000 * 3600 * 24));
      return daysDelta > 90;
    },
    customDateIsEmpty(from, to) {
      return !from.isValid() || !to.isValid();
    },
    customInputValid(period, dates) {
      // Verify if input fieds contains dates
      if (period === this.customPeriodOption.date && this.customDateIsEmpty(this.moment(dates.from), this.moment(dates.to))) {
        this.customDatesInputEmpty = true;
        return false;
      }

      // Verify if start date is greater than end date
      if (period === this.customPeriodOption.date && this.customDatesAreInversed(this.moment(dates.from), this.moment(dates.to))) {
        this.customDatesInversed = true;
        return false;
      }

      // Verify if input date interval is greater than 90 days
      if (period === this.customPeriodOption.date && this.customDateIntervalOver90Days(this.moment(dates.from), this.moment(dates.to))) {
        this.intervalOver90Days = true;
        return false;
      }

      return true;
    },
    restrictFuturDates() {
      return new Date().toISOString().split('T')[0];
    }
  },
};
</script>

<style scoped>
.body-container {
  background-color: #efefef;
}

.report {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: calc(100vh - 122px);
  background-color: #efefef;
}
.device-box {
  min-width: 200px;
  margin: 30px;
}

.rule-name {
  flex: 0 0 50%;
}

.rule-name-label {
  border-bottom: 1px solid #ddd
}

.rule-occurence-small {
  display: block;
  padding-left: 8px;
}

.event-containers {
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  display: none;
}
.event-container {
  justify-content: space-between;
  display: flex;
  padding: 10px 18px;
  /* cursor: pointer; */
}
.device-info-text,

.icon-container {
  padding-left: 0;
}
input[type='checkbox'] {
  display: none;
}

label {
  width: 100%;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
}
.item {
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
  border-radius: 10px;
  margin-bottom: 10px;
  width: 500px;
  max-width: 700px;
  
}

input[type='checkbox']:checked + label {
  background-color: #ddd;
}

input[type='checkbox']:checked + label + div {
  display: flex;
  flex-direction: column;
}

.device-name-container-report {
  justify-content: start;
  padding-top: 16px;
}

.device-info {
  border-top: none;
}

.event-containers .event-container:nth-child(odd) {
  background-color: #fff;
}

.event-containers .event-container:nth-child(even) {
  background-color: #f2f2f2;
}
.event-containers > div:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.closeBtn-container {
  text-align: right;
  margin-right: 53px;
  margin-top: 19px;
  padding-top: 0px;
}

.closeBtn {
  border:  1px solid black;
  padding-top: 0px;
  padding-bottom: 2px;
}

.report-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.timestamps {
  min-width: 250px;
  max-width: 250px;
  float: left;
  background-color: #fff;
  padding: 20px;
}

.report-cards {
  overflow-y: scroll;
  width: auto;
  height: calc(100vh - 116px);
  float: left;
  border-left: 1px solid rgb(220, 220, 220);
  padding-left: 16px;
  padding-right: 16px;
  background-color: #fff;
  flex: 1;
}

.period-label:hover {
  color: rgb(0, 179, 0);
  font-weight: 500;
}

.period-label {
  color: #444;
  border: none;
  outline: none;
  background: none;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
}

.period-label-selected {
  color: rgb(0, 179, 0);
  font-weight: 500;
  text-decoration: none;
}

.period-container {
  margin-top: 10px;
}

.custom-date-input-container {
  margin-top: 6px;
  padding-left: 6px;
}

.custom-date-input {
  color: #444;
  line-height: 20px;
  font-size: 14px;
  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  padding-top: 2px;
  padding-left: 4px;
  padding-right: 2px;
}

.custom-date-input-error {
  line-height: 20px;
  font-size: 14px;
  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  border: 1px solid red;
  border-radius: 8px;
  padding-left: 4px;
  padding-right: 2px;
}

.custom-date-input:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}

.apply-custom-date-button {
  background: none;
  font-size: 14px;
  border-color: #ccc;
  border-radius: 8px;
  margin-top: 8px;
}

.apply-custom-date-button:hover {
  font-weight: 500;
}

.no-exception-to-show {
  padding: 30px;
}

.invalid-input-message {
  font-size: 13px;
  font-style: italic;
  color: red;
  margin-top: 4px;
  margin-left: 6px;
  margin-right: 6px;
}

.loading-animation {
  text-align: center;
  margin-top: 40px;
}

.loading-animation-container {
  display: flex;
  justify-content: center;
}

.device-box-container {
  border-bottom: 2px solid #ddd;
}

.rule-chevron-icon-container {
  margin-left: auto;
}

/* Styling for smaller screens, ie: smartphones and tablets */
@media only screen and (min-width: 1450px) {
  .report-container {
    margin-left: 275px;
    margin-right: 275px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
}

@media only screen and (max-width: 840px) {
  .report-container { 
    flex-direction: column;
  }

  .timestamps {
    display: flex;
    flex-direction: column;
    min-width: 100%;
  }

  .report-cards {
    border-top: 1px solid rgb(220,220,220);
    border-left: 0;
  }

  .custom-date-input-container {
    display: flex;
    justify-content: center;
  }

  .period-container {
    display: inline-flex;
    justify-content: center;
  }

  .custom-date-input-container-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .invalid-input-message {
    display: flex;
    justify-content: center;
  }

  .to-label {
    color:#444;
    margin: 1px 6px 0 6px;
  }

  .period-label {
    padding: 1px 12px 6px 6px;
    margin: 0;
  }

  .invalid-input-message {
    font-size: 13px;
    font-style: italic;
    color: red;
    margin-top: 4px;
    margin-left: 6px;
  }

  .apply-custom-date-button {
    background: none;
    font-size: 14px;
    border-color: #ccc;
    border-radius: 8px;
    margin-top: 0px;
    margin-left: 8px;
  }

  .item {
    width: 100%;
  }

  .device-box {
    margin: 0;
    min-width: 200px;
  }

  .device-info {
    margin: 5px 10px;
  }

  .device-name-container-report {
    margin: 10px 0 0 10px;
    display: flex;
    justify-content: center;
  }

  .to-from-date-input-container {
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (max-width: 355px) {
  .custom-date-input-container {
    display: flex;
    flex-direction: column;
  }
  
  .apply-custom-date-button {
    margin-top: 4px;
    margin-left: 0;
  }
}


</style>
