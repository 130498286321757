<template>
    <div>
        <div id="myModal" class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <span class="modal-title" id="exampleModalLabel">{{ title}}</span>
                        <button type="button" class="btn btn-outline-secondary btn-sm" @click="hideModal" aria-label="Close">
                          <font-awesome-icon icon="fa-solid fa-xmark" />
                        </button>
                    </div>
                    <div class="modal-body">
                        {{ message }}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary btn-sm" @click="hideModal()">{{ closeButton }}</button>
                        <button v-if="submitButton" @click="confirm(), hideModal()" type="button" class="btn btn-primary btn-sm">{{ submitButton }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap'

export default {
    name: "ModalComponent",
    data() {
        return {
            modalInstance: null
        }
    },
    props: {
      showModal: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        required: true
      },
      message: {
        type: String,
        required: true
      },
      closeButton: {
        type: String,
        required: false
      },
      submitButton: {
        type: String,
        required: false
      }
    },
    watch: {
    showModal(newValue, oldValue) {
      if (newValue === true) {
        this.modalActive();
      }
    }
  },
  methods: {
    modalActive: function () {
      this.modalInstance = new Modal(document.getElementById('myModal'), {
        target: "#my-modal",
        backdrop: "static"
      });
      this.modalInstance.show()
    },
    hideModal: function () {
      this.modalInstance.hide();
      this.$emit('closeModal');
    },
    confirm() {
        this.$emit('confirm');
    }
  }
}

</script>

<style scoped>
.modal-title {
  font-size: 23px;
  font-weight: 500;
}
</style>