import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router'
import store from './stores/store'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCircleExclamation, faUser, faWater, faCheck, faShieldHalved, faTemperatureHalf, faXmark, faEnvelope, faPlus, faMinus, faLock, faLockOpen, faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import i18n from './i18n'
library.add(faUser, faCircleExclamation, faWater, faCheck, faShieldHalved, faTemperatureHalf, faXmark, faEnvelope, faPlus, faMinus, faLock, faLockOpen, faBars, faChevronDown)

const app = createApp(App)
    .use(i18n)
    .use(store)
    .use(router)

app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')
