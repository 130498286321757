<template>
  <div class="map-container">
    <div id="map-container"></div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

export default {
  name: 'MapComponent',
  data() {
    return {
      map: '',
    };
  },
  props: ['api', 'rulesPerDevices', 'devicesList'],

  methods: {
    displayMap(exceptions) {
      mapboxgl.accessToken =
        'pk.eyJ1IjoibHBwYXBpbGxvbiIsImEiOiJjaWhldm94a3kwNDR1dGpqN3UwNm5jNDBzIn0.LurMz2eqhvTDoh1M8dlvzg';

      const map = new mapboxgl.Map({
        container: 'map-container', // container ID
        style: 'mapbox://styles/mapbox/streets-v11', // style URL
        center: [-74.5, -40], // starting position [lng, lat]
        zoom: 7, // starting zoom
      });

      map.addControl(new mapboxgl.NavigationControl());

      let boundsX = [],
        boundsY = [],
        bounds = [];
      let that = this;
      exceptions.forEach(function (markerData) {

        boundsX.push(markerData.latitude);
        boundsY.push(markerData.longitude);

        const el = document.createElement('div');
        el.className = `marker ${that.devicesList[markerData.device.id].group}`;
        
        var marker = new mapboxgl.Marker(el)
          .setLngLat([markerData.longitude, markerData.latitude])
          .addTo(map);

        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          offset: [0, -20]
        }).setHTML(that.rulesPerDevices[markerData.device.id].name);
        marker.setPopup(popup).togglePopup();
      });
      if (boundsY.length) {
        bounds = [
          [Math.min.apply(Math, boundsY), Math.min.apply(Math, boundsX)],
          [Math.max.apply(Math, boundsY), Math.max.apply(Math, boundsX)],
        ];

        map.fitBounds(bounds, {
          padding: 50,
          animate: false,
        });
      }

      if (Object.keys(that.devicesList).length === 1) {
        map.setZoom(13);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
#map-container .mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border: none !important;
}
.mapboxgl-popup-content {
  padding: 5px !important;
}
.marker {
  background-image: url('../assets/T-BOX.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  #map-container {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
