<template>
    <div class="settings-main-container">
        <div class="page-container">
            <!-- Cancel modifications confirmation modal -->
            <Modal :title="$t('settings.mailing_list.confirm_cancelation')"
                   :message="$t('settings.mailing_list.confirm_cancelation_message')"
                   :closeButton="$t('settings.mailing_list.close_modal')"
                   :submitButton="$t('settings.mailing_list.confirm_label')"
                   @confirm="resetCard()" @closeModal="dataModal = false" :showModal="dataModal"/>
            <div class="panel-container">
                <div class="mailing-list-container">
                    <div class="mailing-list-icon">
                        <font-awesome-icon icon="fa-solid fa-envelope" />
                    </div>
                    <div class="mailing-list-label">
                        {{ $t('settings.mailing_list.label') }}
                    </div>
                </div>
            </div>
            <div class="settings-container">
                <div v-if="!loaded" class="loading-container">
                    <Loading/>
                </div>
                <div v-else-if="isError" class="error-msg-container">
                    <span>{{ errorMessage }}</span>
                </div>
                <div v-else v-for="(mapping, i) in this.mappings" :key="i" class="rule-card">
                    <div>
                        <div class="card-item-container">
                            <div class="card-section-label">
                                {{ $t("settings.mailing_list.rule_name") }}
                            </div>
                            <div class="rule-label">
                                {{ mapping.name }}
                            </div>
                        </div>
                        <div class="card-item-container">
                            <div class="card-section-label">
                                {{ $t("settings.mailing_list.emails") }}
                            </div>
                            <div class="element-plus-container">
                                <div class="item-container">
                                    <div>
                                        <table class="table table-striped">
                                            <tbody>
                                            <tr v-for="(email, j) in mapping.email_recipients" :key="j">
                                                <td class="card-element" :id="`card-element_${i}_${j}`">
                                                    <div class="row-element">
                                                        <div class="row-element-left">
                                                            {{ email }}
                                                        </div>
                                                        <div class="row-element-right">
                                                            <button @click="deleteEmail(email, i)" class="delete-item-action-btn">
                                                                <font-awesome-icon icon="fa-solid fa-xmark" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr :id="`emailInput_${i}`" style="display: none">
                                                <td class="card-element" id="emailInputWrapper">
                                                    <div class="input-field-action-container">
                                                        <div class="input-field-container">
                                                            <input type="email" 
                                                                   :class="emailIsValid ? 'form-control input-field' : 'form-control input-field-invalid'" 
                                                                   :id="`emailInputField_${i}`" 
                                                                   placeholder="" 
                                                                   v-on:keyup.enter="addNewEntry('email', i)">
                                                        </div>
                                                        <div class="input-add-btn-container">
                                                            <button @click="addNewEntry('email', i)" type="button" class="btn btn-success btn-sm">
                                                                <font-awesome-icon icon="fa-solid fa-plus" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div v-if="!emailIsValid" class="invalid-input-message">
                                                        <span>{{ $t('settings.mailing_list.invalid_email') }}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="plus-btn-container">
                                    <button :id="`emailMinusBtn_${i}`" class="plus-btn" @click="showRemoveIcon('email', i)" style="display: none;">
                                        <font-awesome-icon icon="fa-solid fa-minus" />
                                    </button>
                                    <button :id="`emailPlusBtn_${i}`"  class="plus-btn" @click="addNew('email', i)">
                                        <font-awesome-icon icon="fa-solid fa-plus" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-item-container">
                            <div class="card-section-label">
                                {{ $t("settings.mailing_list.sms") }}
                            </div>
                            <div class="element-plus-container">
                                <div class="item-container">
                                    <table class="table table-striped">
                                        <tbody>
                                        <tr v-for="(sms, j) in mapping.sms_recipients" :key="j">
                                            <td class="card-element">
                                                <div class="row-element">
                                                    <div class="row-element-left">
                                                        {{ sms }}
                                                    </div>
                                                    <div class="row-element-right">
                                                        <button @click="deleteSMS(sms, i)" class="delete-item-action-btn">
                                                            <font-awesome-icon icon="fa-solid fa-xmark" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr :id="`smsInput_${i}`" style="display: none">
                                                <td class="card-element" id="smsInputWrapper">
                                                    <div class="input-field-action-container">
                                                        <div class="input-field-container">
                                                            <input type="text" 
                                                                   class="form-control input-field" 
                                                                   :id="`smsInputField_${i}`" 
                                                                   placeholder="" 
                                                                   v-on:keyup.enter="addNewEntry('sms', i)">
                                                        </div>
                                                        <div class="input-add-btn-container">
                                                            <button @click="addNewEntry('sms', i)" type="button" class="btn btn-success btn-sm">
                                                                <font-awesome-icon icon="fa-solid fa-plus" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div v-if="!smsIsValid" class="invalid-input-message">
                                                        <span>{{ $t('settings.mailing_list.invalid_sms') }}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="plus-btn-container">
                                    <button :id="`smsMinusBtn_${i}`" class="plus-btn" @click="showRemoveIcon('sms', i)" style="display: none">
                                        <font-awesome-icon icon="fa-solid fa-minus" />
                                    </button>
                                    <button :id="`smsPlusBtn_${i}`" class="plus-btn" @click="addNew('sms', i)">
                                        <font-awesome-icon icon="fa-solid fa-plus" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-action-container">
                    <div class="action-btn-container">
                        <div class="save-cancel-btn">
                            <button @click="saveAll()" type="button" class="btn btn-success btn-sm">{{ $t('settings.mailing_list.save') }}</button>
                        </div>
                        <div class="save-cancel-btn" data-toggle="modal" data-target="#exampleModal">
                            <button type="button" class="btn btn-outline-secondary btn-sm" @click="dataModal = true" >{{ $t('settings.mailing_list.cancel') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import Loading from './Loading.vue';
import Modal from './Modal.vue'

export default {
    name: "SettingsComponent",
    components: { Loading, Modal },
    data() {
        return {
            user: '',
            api: {},
            group: '',
            payload: {},
            mappings: [],
            originalData: {},
            dataModal: false,
            emailIsValid: true,
            smsIsValid: true,
            loaded: true,
            isError: false,
            errorMessage: ''
        }
    },
    created() {
        this.user = JSON.parse(localStorage.getItem('geotabAPI_credentials')).userName
        this.initialize();
    },
    methods: {
        async initialize() {
            this.loaded = false;
            this.getApi();
            await this.setOriginalData();
            this.loaded = true;
        },
        getApi() {
            this.api = this.$store.getters.getApi;
        },
        getRules() {
            return Object.values(this.$store.getters.getFilteredRules);
        },
        async getUrl() {
            await this.getUserGroup();
            return `https://systems.attrix.ca/api/niu/niu_toilette/${this.group}/settings?user=${this.user}`;
        },
        async getUserGroup() {
            try {
               await this.api.call("Get", {
                    "typeName": "User",
                    "search": {
                        "name": this.user
                    }
                }, (result) => {
                    this.group = result[0].companyGroups[0].id;
                }, (e) => {});
            } catch {
                this.isError = true;
                this.errorMessage = this.$t('notification.error-occured-message');
            }
        },
        async getData() {
            try {
                const response = await fetch(await this.getUrl());
                return response.json();
            } catch {
                this.isError = true;
                this.errorMessage = this.$t('notification.error-occured-message');
            }
        },
        setMappings() {
            for (var rule of JSON.parse(JSON.stringify(this.getRules()))) {
                this.mappings.push({
                    rule_id: rule.id,
                    email_recipients: [],
                    sms_recipients: []
                });
            }
        },
        addNew(type, index) {
            if (type === 'email') {
                this.displayInputField('email', index);
            }
            if (type === 'sms') {
                this.displayInputField('sms', index);
            }
        },
        showRemoveIcon(type, index) {
            if (type === 'email') {
                this.hideInputField('email', index);
                this.emailIsValid = true;
            }
            if (type === 'sms') {
                this.hideInputField('sms', index);
                this.smsIsValid = true;
            }
        },
        displayInputField(type, index) {
            if (type === 'email') {
                document.getElementById('emailInput_' + index).style.display = 'contents';
                document.getElementById('emailPlusBtn_' + index).style.display = 'none';
                document.getElementById('emailMinusBtn_' + index).style.display = 'block';
            }
            if (type === 'sms') {
                document.getElementById('smsInput_' + index).style.display = 'contents';
                document.getElementById('smsPlusBtn_' + index).style.display = 'none';
                document.getElementById('smsMinusBtn_' + index).style.display = 'block';
            }
        },
        hideInputField(type, index) {
            if (type === 'email') {
                document.getElementById('emailInput_' + index).style.display = 'none';
                document.getElementById('emailMinusBtn_' + index).style.display = 'none';
                document.getElementById('emailPlusBtn_' + index).style.display = 'block';
            }
            if (type === 'sms') {
                document.getElementById('smsInput_' + index).style.display = 'none';
                document.getElementById('smsMinusBtn_' + index).style.display = 'none';
                document.getElementById('smsPlusBtn_' + index).style.display = 'block';
            }
        },
        async saveAll() {
            await this.postRequest(JSON.stringify(this.createPayload()));
            this.initialize();
        },
        createPayload() {
            let payload = {};
            payload.mappings = [];
            
            payload.user = JSON.parse(localStorage.getItem("geotabAPI_credentials")).userName;

            for (var mapping of this.mappings) {
                if (mapping.email_recipients !== null && mapping.sms_recipients !== null && mapping.email_recipients.length > 0 && mapping.sms_recipients.length > 0 ) {
                    payload.mappings.push({
                        rule_id: mapping.rule_id,
                        email_recipients: mapping.email_recipients.toString(),
                        sms_recipients: mapping.sms_recipients.toString()
                    })
                } else if (mapping.email_recipients !== null && mapping.email_recipients.length > 0 ) {
                    payload.mappings.push({
                        rule_id: mapping.rule_id,
                        email_recipients: mapping.email_recipients.toString(),
                        sms_recipients: null
                    })
                } else if (mapping.sms_recipients !== null && mapping.sms_recipients.length > 0 ) {
                    payload.mappings.push({
                        rule_id: mapping.rule_id,
                        email_recipients: null,
                        sms_recipients: mapping.sms_recipients.toString()
                    })
                } else {
                    payload.mappings.push({
                        rule_id: mapping.rule_id,
                        email_recipients: null,
                        sms_recipients: null
                    })
                }
            }
            return payload;
        },
        resetCard() {
            for (var i = 0 ; i < this.mappings.length; i++) {
                this.mappings[i].email_recipients = cloneDeep(this.originalData.mappings[i].email_recipients);
                this.mappings[i].sms_recipients = cloneDeep(this.originalData.mappings[i].sms_recipients);
            }
            this.dataModal = true;
        },
        deleteEmail(email, index) {
            const itemIndex = this.mappings[index].email_recipients.indexOf(email);
            this.mappings[index].email_recipients.splice(itemIndex, 1);
        },
        deleteSMS(sms, index) {
            const itemIndex = this.mappings[index].sms_recipients.indexOf(sms);
            this.mappings[index].sms_recipients.splice(itemIndex, 1);
        },
        addNewEntry(type, index) {
            if (type === 'email') {
                this.addNewEmailRecipient(index)
            }
            if (type === 'sms') {
                this.addNewSMSRecipient(index);
            }
        },
        async postRequest(payload) {
            this.loaded = false;
            const url = await this.getUrl();

            try {
                await fetch(url, {
                    method: "POST",
                    body: payload,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                });
            } catch(e) {
                this.isError = true;
                this.errorMessage = this.$t('notification.post-error-occured-message');
            }
            this.loaded = true;
        },
        toggleModal() {
            this.showModal = true;
        },
        addNewEmailRecipient(index) {
            const emailValidator = /^\S+@\S+\.\S+$/;

            if (document.getElementById('emailInputField_' + index)) {
                const isValid = emailValidator.test(document.getElementById('emailInputField_' + index).value);
                if (isValid) {
                    this.mappings[index].email_recipients = (this.mappings[index].email_recipients + '').split(',')
                    this.mappings[index].email_recipients.push(document.getElementById('emailInputField_' + index).value);
                    this.removeNullValue(this.mappings[index].email_recipients);
                    this.hideInputField('email', index);
                    this.emailIsValid = true;
                } else {
                    this.emailIsValid = false;
                }
            }
        },
        addNewSMSRecipient(index) {
            let smsElement = document.getElementById('smsInputField_' + index);

            if (smsElement) {
                if (smsElement.value.length > 0) {
                    this.mappings[index].sms_recipients = (this.mappings[index].sms_recipients + '').split(',')
                    this.mappings[index].sms_recipients.push(smsElement.value.replace(/\D/g, ""));
                    this.removeNullValue(this.mappings[index].sms_recipients);
                    this.hideInputField('sms', index);
                    this.smsIsValid = true;
                } else {
                    this.smsIsValid = false;
                }
            }
        },
        async setOriginalData() {
            const getDataResult = await this.getData().then(res => res);
            if (JSON.stringify(getDataResult) === JSON.stringify({})) {
                this.setMappings();
                this.originalData.mappings = cloneDeep(this.mappings);
            } else {
                this.originalData = getDataResult;
                for (var i = 0; i < this.originalData.mappings.length; i++) {
                    if (this.originalData.mappings[i].email_recipients !== null) {
                        this.originalData.mappings[i].email_recipients = this.originalData.mappings[i].email_recipients.split(',');
                    }
                    if (this.originalData.mappings[i].sms_recipients !== null) {
                        this.originalData.mappings[i].sms_recipients = this.originalData.mappings[i].sms_recipients.split(',');
                    }
                }
                this.mappings = cloneDeep(this.originalData.mappings);
            }
            this.mapRuleName();
        },
        mapRuleName() {
            let ruleIdsFromMapping = [];
            let rulesFromFilteredRules = Object.values(this.$store.getters.getFilteredRules);

            for (var mapping of this.mappings) {
                ruleIdsFromMapping.push(mapping.rule_id);
            }

            for (var i = 0; i < rulesFromFilteredRules.length; i++) {
                for (var j = 0; j < rulesFromFilteredRules.length; j++) {
                    if (rulesFromFilteredRules[j].id === ruleIdsFromMapping[i]) {
                        this.mappings[i].name = rulesFromFilteredRules[j].name;
                    }
                }
            }
        },
        removeNullValue(list) {
            const index = list.indexOf('null');
            if (index !== -1) {
                return list.splice(index, 1);
            }
            return list;
        }
    }
}
</script>

<style scoped>
.settings-main-container {
    background-color: rgb(220,220,220);
    height: 100%
}

.page-container {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    height: calc(100vh - 116px);
}

.panel-container {
    border-right: 1px solid rgb(210, 210, 210);
    width: 250px;
}

.settings-container {
    margin-bottom: 52px;
    flex: 1;
    overflow-y: scroll;
}

.mailing-list-container {
    margin-top: 14px;
    display: inline-flex;
    height: 36px;
    width: 100%;
    padding-top: 7px;
    padding-left: 4px;
}

.mailing-list-icon {
    margin-left: 15px;
    color: rgb(100, 100, 100);
}

.mailing-list-label {
    margin-left: 15px;
    color: rgb(100, 100, 100);
    font-weight: 500;
}

.rule-card {
    min-width: 500px;
    border-bottom: 2px solid #ddd;
    margin: 16px;
    padding: 13px 13px 13px 22px;
}

.rule_label {
    font-weight: 500;
    font-size: 15px;
}

.card-item-container {
    max-width: 550px;
    margin-bottom: 13px;
}

.item-container {
    width: 60%;
}

.card-element {
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    padding: 10px;
}

.card-section-label {
    color: #777;
}

.element-plus-container {
    display: flex;
    flex-direction: row;
}

.plus-btn-container {
    margin-left: 24px;
}

.plus-btn {
    border: none;
    background: none;
}

.input-field {
    font-size: 14px;
}

.input-field-invalid {
    border: 1px solid red;
}

.row-element {
    display: flex;
    flex-direction: row;
}

.row-element:hover > .row-element-right {
    display: inline;
    -webkit-animation: fadeIn 300ms ease-in;
    -moz-animation: fadeIn 300ms ease-in;
    animation: fadeIn 300ms ease-in;
}

.row-element-left {
    flex: 1;
}

.row-element-right {
    display: none;
}

.delete-item-action-btn {
    border: none;
    background: none;
    color: #ff0000;
    padding-top: 0px;
    padding-bottom: 0px;
}

.action-btn-container {
    display: flex;
    flex-direction: row;
}

.save-cancel-btn {
    margin-right: 9px;
}

.input-field-action-container {
    display: flex;
    flex-direction: row;
}

.input-field-container {
    flex: 1;
}

.input-add-btn-container {
    margin-top: 2px;
    margin-left: 8px;
}

.invalid-input-message {
    color: red;
    font-size: 13px;
    margin-top: 3px;
}

.form-action-container {
    border-top: 1px solid rgb(210, 210, 210);
    padding-top: 10px;
    padding-left: 17px;
    padding-bottom: 10px;
    position: sticky;
    bottom: 52px;
    position: fixed;
    width: 100%;
    background-color: #fff;
    display: flex;
}

.itemCheckbox {
    border: 1px solid #bbb;
    border-radius: 4px;
    width: 17px;
    height: 17px;
    margin-top: 2px;
}

.checkbox-container {
    color: #555;
}

.checkbox {
    width: 13px;
    height: 13px;
    padding-bottom: 2px;
    padding-left: 1px;
}

.checkbox-button {
    border: none;
    background: none;
    padding: 0;
}

.delete-edit-actions-container {
    display: flex;
}

.delete-btn-container {
    margin-left: 4px;
    margin-top: 4px;
    color: #666;
}

.edit-btn-container {
    margin-left: 10px;
}

.error-msg-container {
    padding: 10px;
}

.loading-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

@media only screen and (min-width: 1450px) {
    .page-container {
        margin-left: 275px;
        margin-right: 275px;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
    }
}

/* Small screen devices */
@media only screen and (max-width: 770px) {
    .page-container {
        flex-direction: column;
    }

    .panel-container {
        border: none;
        width: 100%;
        border-bottom: 1px solid rgb(220, 220, 220);
    }

    .mailing-list-container {
        background-color: #fff;
        border: none;
        margin-top:  15px;
        padding: 0;
    }

    .row-element-right {
        display: block;
    }

    .delete-item-action-btn {
        border: none;
        background: none;
        color: #999;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .close-report-container {
        position: absolute;
        width: 24px;
        height: 24px;
        margin-left: 93vw;
        margin-top: 4vw
    }

    .rule-card {
        min-width: 300px;
        border-bottom: 2px solid #ddd;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        margin: 16px 0;
        padding: 13px 30px 13px 30px;
    }
}

@media only screen and (max-width: 475px) {
    .rule-card {
        min-width: 300px;
        border-bottom: 2px solid #ddd;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        margin: 16px 0;
        padding: 13px 30px 13px 30px;
    }

    .item-container {
        width: 80%;
    }

    .form-action-container {
        justify-content: center;
    }
}

/* Animations */
@-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@-moz-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
</style>
