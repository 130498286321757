export default {
  "authentication": {
    "username_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant"])},
    "password_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "login_button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "logout_button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "Invalid credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations d'identification invalides"])}
  },
  "device": {
    "lock-unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verrouiller/déverrouiller"])}
  },
  "rules": {
    "waste_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservoir d'eaux noires OK"])},
    "waste_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservoir d'eaux noires plein"])},
    "security_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune sécurité sur place"])},
    "security_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurité sur place"])},
    "toilet_1_panic_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouton panique des toilettes 1 inactif"])},
    "toilet_1_panic_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouton panique des toilettes 1 actif"])},
    "toilet_2_panic_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouton panique des toilettes 2 inactif"])},
    "toilet_2_panic_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouton panique des toilettes 2 actif"])},
    "panic_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouton panique désactivé"])},
    "panic_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouton panique activé"])},
    "toilet_1_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilettes 1 libres"])},
    "toilet_1_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilettes 1 occupées"])},
    "toilet_2_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilettes 2 libres"])},
    "toilet_2_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilettes 2 occupées"])},
    "toilet_3_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilettes 3 libres"])},
    "toilet_3_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilettes 3 occupées"])},
    "water_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservoir d'eaux propres 5%"])},
    "water_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservoir d'eaux propres 25%"])},
    "water_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau d'eau OK"])},
    "temperature_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Température OK"])},
    "temperature_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerte de température"])}
  },
  "timestamp": {
    "Today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
    "Yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier"])},
    "This-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette semaine"])},
    "Last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La semaine dernière"])},
    "Custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisée"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer les modifications"])},
    "90days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plage de dates ne peut pas dépasser 90 jours."])},
    "custom_from_to_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de début ne peut être plus grande que la date de fin."])},
    "inputProperly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurez-vous que la date et l'heure sont saisies correctement."])},
    "date_input_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir les champs de date."])},
    "date_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aaaa-mm-jj"])},
    "apply_custom_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer"])},
    "occurences": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " fois durant"]), _normalize([_interpolate(_named("n")), " fois durant"])])}
  },
  "header": {
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
    "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispositifs"])}
  },
  "footer": {
    "powered_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr"])}
  },
  "notification": {
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerte"])},
    "error-occured-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue."])},
    "post-error-occured-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue. Les modifications n'ont pas été sauvegardées."])},
    "iox-output-relay-message-succes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'appareil a été verrouillé/déverrouillé."])},
    "iox-output-relay-message-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenu. Aucun signal n'a été envoyé."])}
  },
  "exception": {
    "no-exception-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune exception trouvée"])}
  },
  "settings": {
    "mailing_list": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataires"])},
      "rule_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règle"])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriels"])},
      "sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "close_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
      "confirm_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
      "confirm_cancelation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler les modifications?"])},
      "confirm_cancelation_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les modifications apportées ne seront pas sauvegardées."])},
      "invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel invalide"])},
      "invalid_sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS invalide"])}
    }
  }
}