export default {
  "authentication": {
    "username_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "password_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "login_button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "logout_button_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "Invalid credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid credentials"])}
  },
  "device": {
    "lock-unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lock/unlock"])}
  },
  "rules": {
    "waste_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waste tank ok"])},
    "waste_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waste tank full"])},
    "security_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security not on site"])},
    "security_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security on site"])},
    "toilet_1_panic_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilet 1 panic button inactive"])},
    "toilet_1_panic_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilet 1 panic button active"])},
    "toilet_2_panic_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilet 2 panic button inactive"])},
    "toilet_2_panic_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilet 2 panic button active"])},
    "panic_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panic button inactive "])},
    "panic_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panic button active"])},
    "toilet_1_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilet 1 vacant"])},
    "toilet_1_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilet 1 occupied"])},
    "toilet_2_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilet 2 vacant"])},
    "toilet_2_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilet 2 occupied"])},
    "toilet_3_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilet 3 vacant"])},
    "toilet_3_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilet 3 occupied"])},
    "water_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water tank 5%"])},
    "water_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water tank 25%"])},
    "water_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water level ok"])},
    "temperature_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature OK"])},
    "temperature_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature alert"])}
  },
  "timestamp": {
    "Today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
    "Yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
    "This-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This week"])},
    "Last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last week"])},
    "Custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply changes"])},
    "inputProperly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure the date time is properly inputted."])},
    "90days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The date range cannot be greater than 90 days."])},
    "custom_from_to_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The start date cannot be greater than the end date."])},
    "date_input_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in the date fields."])},
    "date_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yyyy-mm-dd"])},
    "apply_custom_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "occurences": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " time for"]), _normalize([_interpolate(_named("n")), " times for"])])}
  },
  "header": {
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])}
  },
  "footer": {
    "powered_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])}
  },
  "notification": {
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert"])},
    "error-occured-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred."])},
    "post-error-occured-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred. Changes were not saved."])},
    "iox-output-relay-message-succes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The device has been locked/unlocked."])},
    "iox-output-relay-message-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred. No signal was sent."])}
  },
  "exception": {
    "no-exception-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No exception found"])}
  },
  "settings": {
    "mailing_list": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailing List"])},
      "rule_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rule"])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emails"])},
      "sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "close_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "confirm_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "confirm_cancelation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel modifications?"])},
      "confirm_cancelation_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes made will not be saved."])},
      "invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email"])},
      "invalid_sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid SMS"])}
    }
  }
}