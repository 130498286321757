<template>
  <div class="locale-container">
    <div>
      <button @click="changeLanguage('fr')" type="button" class="btn btn-link locale-item-en">fr</button>
    </div>
    <div>
      <button @click="changeLanguage('en')" type="button" class="btn btn-link locale-item-fr">en</button>
    </div>
  </div>
</template>
  
<script>
import GeotabApi from 'mg-api-js';

export default {
  name: "LocaleSwitcher",
  data() {
    return {
      api: {}
    }
  },
  created() {
    this.localStorage = JSON.parse(
      localStorage.getItem('geotabAPI_credentials')
    );
    if(this.localStorage){
      this.api = new GeotabApi(this.getCredentials());
    }
  },
  methods: {
    getCredentials() {
      if(this.isLoggedIn()){
        return {
          credentials: {
            database: this.localStorage.database,
            sessionId: this.localStorage.sessionId,
            userName: this.localStorage.userName,
          },
          path: 'my.geotab.com',
        };
      } else {
        return false;
      }
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      this.api = new GeotabApi(this.getCredentials());

      // Set preferred language in user database
      try {
        this.api.call("Get", {
          "typeName": "User",
          "search": {
              "name": this.getCredentials().credentials.userName
          }
        }, (user) => {
          this.api.call("Set", {
            "entity": {
              "id": user[0].id,
              "language": lang
            },
            "typeName": "User"
          })
        }, (err) => {})
      } catch(e) { 
      }
    },
    isLoggedIn() {
      this.$store.commit('verifyCredentials');
      return this.$store.getters.getLogin;
    },
  }
};
</script>

<style scoped>
.locale-container {
  width: 100%;
}

.locale-item-en {
  width: 20%;
  float: left;
  padding: 0;
  color: rgb(60, 60, 60);
  text-decoration: none;
}

.locale-item-en:hover {
  font-weight: 500;
  text-decoration: none;
  color: rgb(60, 60, 60);
}

.locale-item-fr:hover {
  font-weight: 500;
  text-decoration: none;
  color: rgb(60, 60, 60);
}

.locale-item-fr {
  width: 35%;
  padding: 0;
  color: rgb(60, 60, 60);
  text-decoration: none;
}
</style>
  