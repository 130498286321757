<template>
    <div class="sign-out-button-container" id="sign-in-button">
        <button @click="logout()" class="logout-btn">{{ $t('authentication.logout_button_label') }}</button>
    </div>
</template>

<script>

export default {
    name: 'LogoutButtonComponent',
    props: {
        placeholder: String
    },
    methods: {
        logout() {
            this.$store.commit('logout')
            this.$router.push({ name: 'login' })
            this.$emit('logoutEvent')
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logout-btn {
    color: rgb(60,60,60);
    border: none;
    background: none;
    z-index: 999;
}

.logout-btn:hover {
    cursor: pointer;
    font-weight: 500;
    color: rgb(0,179,0);
}
</style>
