import { createRouter, createWebHistory } from 'vue-router'
import AuthenticationForm from '@/components/AuthenticationForm.vue'
import DeviceList from '@/components/DeviceList'
import Report from '@/components/Report'
import Settings from '@/components/Settings'

const routes = [
  {
    path: '/',
    name: 'index',
    component: DeviceList
  },
  {
    path: '/login',
    name: 'login',
    component: AuthenticationForm
  },
  {
    path: '/reports',
    name: 'reports',
    component: Report
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
