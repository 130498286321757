<template>
<div class="form-container-wrapper">
    <div class="form-container">
        <form>
            <div class="form-group">
                <div v-if="isInvalidAuthentication" class="invalid-credentials-box">
                    {{ $t('Invalid credentials') }}
                </div>
                <label id="usernameLabel" for="usernameLabel">{{ $t('authentication.username_input') }}</label>
                <input type="text" class="form-control credentialInput" id="usernameInputId" >
            </div>
            <div class="form-group">
                <label id="passwordLabel" for="passwordLabel">{{ $t('authentication.password_input') }}</label>
                <input type="password" class="form-control credentialInput" id="passwordInputId" >
            </div>
            <div class="btn-container">
                <div>
                    <button type="button" class="btn btn-primary" @click="submitForm">{{ $t('authentication.login_button_label') }}</button>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import GeotabApi from "mg-api-js";

export default {
    name: 'AuthenticationFormComponent',
    data() {
        return {
            api: {},
            isInvalidAuthentication: false
        }
    },
    methods: {
        submitForm() {
            const username = document.getElementById("usernameInputId").value;
            const password = document.getElementById("passwordInputId").value;
            
            if (localStorage.getItem("geotabAPI_credentials")) {
                this.authenticateWithSession(username, password);
                this.getLanguage(username);
            } else {
                this.authenticate(username, password);
                this.getLanguage(username);
            }
        },
        testAuthentication() {
            this.api.authenticate().then(() => { 
                this.isInvalidAuthentication = false;
                this.$store.commit('login');
                this.$router.push({ name: 'index' });
            })
            .catch(() => {
                this.isInvalidAuthentication = true;
            });            
        },
        authenticateWithSession(username, password) {
            const authentication = {
                credentials: {
                    database: "niu_toilette",
                    userName: username,
                    password: password,
                    sessionId: JSON.parse(localStorage.getItem("geotabAPI_credentials")).sessionId
                },
                path: "my.geotab.com"
            }
            this.api = new GeotabApi(authentication);
            this.testAuthentication();
        },
        authenticate(username, password) {
            const authentication = {
                credentials: {
                    database: "niu_toilette",
                    userName: username,
                    password: password
                },
                path: "my.geotab.com"
            }
            this.api = new GeotabApi(authentication);
            this.testAuthentication();
        },
        getLanguage(username) {
            this.api.call("Get", {
                "typeName": "User",
                "search": { 
                    "name": username
                }
            }, (res) => {
                this.$i18n.locale = res[0].language;
            }, (err) => {})
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#usernameLabel {
    float: left;
}

#passwordLabel {
    float: left;
}

.btn-container {
    display: flex;
    justify-content: center;
    margin-top: 7px
}

.title-container {
    float: left;
}

.form-container-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.form-container {
    width: 400px;
    height: fit-content;
    border: 1px solid #ccc;
    border-radius: 17px;
    padding: 25px;
}

.credentialInput {
    min-width: 150px;
}

.invalid-credentials-box {
    border: 1px solid #f00;
    border-radius: 3px;
    padding: 10px;
    text-align: center;
    color: #f00;
    margin-bottom: 13px;
}

@media only screen and (max-width: 425px) {
    .form-container {
        width: 250px;
        height: fit-content;
        border: 1px solid #ccc;
        border-radius: 17px;
        padding: 25px;
    }
}
</style>